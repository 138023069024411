import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    build:'/admin/v1/experts/page',//列表
    create:'/admin/v1/experts/create', //创建
    delete:'/admin/v1/experts/del', //删除
    update:'/admin/v1/experts/update', //更新
    get_expert:'/admin/v1/experts/get',
}

//菜单列表
export async function lists(data)
{
    return $h.request(url.build,data,'GET')
}

//创建
export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

//删除
export async function del(data)
{
    return $h.request(url.delete,data,'POST')
}

//更新
export async function update(data)
{
    return $h.request(url.update,data,'POST')
}


export async function get_expert(data)
{
    return $h.request(url.get_expert,data,'GET')
}